/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200;400;600&family=Sansita:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --heading-font: 'Barlow Semi Condensed', sans-serif;
    --content-font: 'Manrope', sans-serif;
    --title-head-color: #001d2f;
    --content-text-color: #555555;
    --black: #000;
    --white: #fff;
    --color-site-blue: #33aafe;
    --primary-color: #ffbe34;
    --light-primary-color: #fcc651;
    --cal-count-color: #9b9b9b;
    --btn-green: #2b6e36;
    --color-theme-orange:#ff7d35;
}

::placeholder {
    font-size: 14px;
    color: #c2c2c2 !important;
}
.page-titles-font {
    font-family: var(--heading-font);
}
p, span, label, small {
    color: var(--content-text-color) ;
}
small {
    font-size: 12px;
}
.medium-font {
    font-size: 14px;
}

body {
    font-family: var(--content-font);
    overflow-x: hidden !important;
}
.container {
    max-width: 1800px;
}

img{
    width: 100%;
    height: auto;
}

.icon-green {
    color: #3aa138;
}

.color-primary {
    color: var(--primary-color);
}

.color-secondary {
    color: var(--color-theme-orange);
}

.mt-100 {
    margin-top: 95px;
}

#header .logo {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header {
    background-color: var(--white);
    // opacity: 0.5;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}
.me-auto {
    margin-right: auto!important;
}
.navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
    color: var(--black);
    white-space: nowrap;
    transition: 0.3s;
}

.navbar .getstarted, .navbar .getstarted:focus {
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    color: var(--white);
    font-size: 14px;
    background-color: var(--color-site-blue);
    font-weight: 400;
    text-decoration: none;
}

.container-bg {
    background-image: url(./assets/images/banner/MakeMyDabba-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    min-height: calc(100vh - 96px);
    background-position: center;
    // padding: 20px 0;
}
.container-bg::after {
    content: "";
    position: absolute;
}

.banner-cnt {
    margin: 50px 0;
}

.container-bg::before {
    content: "";
    position: absolute;
}

.banner-wrap {
    width: 75%;
    align-items: center;
    display: table;
    margin: 0 auto;
}

.banner-item-img {
    width: 460px; 
    margin: 0 0 0 auto;
}

.title-head {
    font-family: var(--heading-font);
    color: var(--black);
    text-align: center;
    // text-transform: uppercase;
    line-height: 3.8rem;
    font-weight: bold;
    font-size: 3.6rem;
    margin-bottom: 25px;
}

.page-headings {
    font-family: var(--heading-font);
    color: var(--title-head-color);
    text-transform: uppercase;
    font-size: 2.5rem;
}

.desp-text {
    font-family: var(--content-font);
    color: var(--black);
    line-height: 1.6rem;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
}

.calc-items hr {
    border: 1px solid #dadada; 
    width: 1px; 
    margin: 0 15px;
}

.cat-name {
    font-size: 14px;
    color: var(--content-text-color);
}
.cat-value {
    color: var(--cal-count-color);
}
.btn-green {
    background-color: var(--btn-green);
    text-transform: uppercase;
}
.btn-theme-yellow {
    background-color: var(--light-primary-color);
}
.btn-theme-orange {
    background-color: var(--color-theme-orange);
}
.btn-round {
    border-radius: 50px;
    padding: 8px 35px;
    color: var(--white);
    font-family: var(--heading-font);
}
.btn-green:hover {
   background-color: #fdbc34 !important;
   color: var(--black);
}

.btn-green.selected {
    background-color: #33aafe !important;
}

.btn-theme-yellow:hover {
    background-color: var(--light-primary-color);
    color: var(--white);
}

.btn-theme-orange:hover {
    background-color: #f28347;
    color: var(--white);
}

.hm-about-Img {
    width: 100%;
}
.about-mg {
    margin: auto 0;
}

.about-msg {
    color: var(--content-text-color);
}

.about-msg p {
    color: var(--content-text-color);
}

.about-theme-bg {
    margin-top: 80px;
}

.what-out-menu {
    margin-bottom: 50px;
}

.sub-page-banner::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    // border-radius: 50px 0 0 50px;
    z-index: -1;
}
.about-theme-bg::after {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, #f5f5f5 100%); 
}
.icon-img {
    width: 75px;
    height: 75px;
}
.box-shape {
    border: 1px solid #dedede;
    border-radius: 10px;
}

.banner-green::after {
    background-color: var(--btn-green);
}
.banner-blue::after {
    background-color: var(--color-site-blue);
}
.banner-yellow::after {
    background-color: var(--primary-color);
}

.about-page-bg::after {
    background-color: #f5f5f5;
}


.menuitem-bg-img {
    // background-image: url(./assets/images/food-item-bg-1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 180px;
}

.contact-bg-img {
    // background-image: url(./assets/images/food-item-bg-2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 180px;
}

.bg-leaf-img::before {
    content: "";
    background-image: url(./assets/images/leaf-image.png);
    background-repeat: no-repeat;
    position: absolute;
    background-size: 125px;
    width: 150px;
    height: 150px;
    z-index: 1;
    top: 25px;
    right: 20%;  
}

.set-label-size {
    font-size: 1.18rem;
}
.date-select-desg {
    background-color: #d4e1d6;
    padding-top: .5rem!important;
    padding-bottom: .5rem!important;
    border-radius: 6px 6px 0 0;
}
.date-select-desg::before {
    content: "";
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #b4c1b6;
    
}

.prod-img{
   width: 100%;
   height: 150px;
}

.prod-img img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.font-min {
    font-size: 12px;
}
.font-mid {
    font-size: 14px;
}

.btn-small {
    font-size: 12px !important;
    padding: 4px 20px;
}
.item-border {
    border: 1px solid #f2f2f2;
}
.btn-ash {
    background-color: #6b6b6b;
}
.btn-ash:hover {
    background-color: #868686;
    color: #fff;
}
.price-tag {
    color: #fff;
    margin-right: 5px;
    border-right: 1px solid #6ea66d;
    display: inline-block;
    padding-right: 10px;
}

.billing-cnt {
    border: 1px solid #f5f5f5;
}
.delvr-text {
    border-bottom: 1px solid #f5f5f5;
}
.delvr-text span {
    display: block;
    font-size: 14px;
}
.discount-color {
    color: var(--btn-green);
}
.total-count-blk {
    border-top: 1px solid var(--title-head-color);
    background-color: #f9f9f9;
}

.checkout-btn {
    border-bottom: 2px solid var(--btn-green) ;
}

.about-list ul {
    list-style: none;
    padding-left: 0;
}

.about-list ul li {
    color: var(--content-text-color);
    font-size: 14px;
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
}

.about-list ul li::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-image: url(./assets/images/check2-circle.svg);
    background-repeat: no-repeat;
    left: 0;
    top: 4px;
    background-position: center;
}

.icon-c-round::after {
    content: "";
    width: 15px;
    height: 15px;
    background-color: #6ea66d;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: 40%;
}
.icon-c-round::before {
    content: "";
    height: 1px;
    width: 80px;
    border: 1px dashed #6ea66d;
    position: absolute;
    right:-12%;
    top: 52%;
}
.color-dark {
    color: #6e6e6e;
}

.faq-accord {
    margin-top: 0px;
}
.faq-accord ul{
    list-style: none;
}
.faq-accord ul li{
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
}
.faq-accord ul li::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: 25px;
    background-repeat: no-repeat;
    right: 15px;
    top: 20px;
}
.faq-accord ul li.icon-minus::after{
    background-image: url(./assets/images/dash-lg.svg);
}
.faq-accord ul li.icon-plus::after{
    background-image: url(./assets/images/plus-lg.svg);
}

.faq-accord ul li span{
    background-color: #f5f5f5;
    padding: 20px 25px;
    display: block;
    color: var(--content-text-color);
    border-radius: 15px;
}
.faq-accord ul li div{

}

.item-descp {
    font-size: 12px;
}

.btn:focus {
    outline: none;
    box-shadow: none;
  }

  .font-sm {
    font-size: 14px;
}
.card-show {
    transition: all 0.5s ease-in;
}
.add-cnt {
    font-size: 0.8rem;
}
.add-cnt span {
    line-height: 1.2rem;
}
.add-cnt:hover {
    background-color: #f0ffdf;
}
.add-cnt.selected {
    background-color: #e6f7d2;
    position: relative;
}
.add-cnt.selected::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(./assets/images/check-circle-fill.svg);
    top: 5px;
    right: 10px;
    background-repeat: no-repeat;
}
.cp-block {
    margin: 0;
    padding: 0;
    display: inline-block;
    justify-content: center;
    list-style-type: none;
    width: 100%;
    margin: 0 auto;
}

.cp-block li {
    display: inline-flex;
    justify-content: space-between;
    padding: 5px 8px;
    width: 100%;
    margin-bottom: 2px;
    font-size: 14px;
    border-bottom:  1px dashed #c9c9c9;
}

.cp-block li:last-child {
    border-bottom: none;
}

.discount-bg {
    color: #3aa138; 
    background-image: url(./assets/images/success.gif); 
    background-repeat: no-repeat; 
    background-position: 95px; 
    background-size: 125px;
}

.vh-75 {
    min-height: 75vh;
}

.position-cart-cnt {
    align-self: flex-start;
    position: sticky;
    top: 120px;
}

.menu-sticky {
    align-self: flex-start;
    position: sticky;
    top: 120px;
}

.hw-order-hd {
    font-weight: 900; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff; 
    opacity: 0.3; 
    font-size: 4.5rem;
}

.min-card-ht {
    min-height: 230px;
    max-height: 230px;
}
h5.page-titles-font {
    font-weight: 500;
}

.about-msg p {
    font-size: 0.95rem;
    line-height: 1.6rem;
}

.card-img {
    overflow: hidden;
}
.card-img img {
    width: 100%;
    height: 175px;
    border-radius: 6px 6px 0 0;
    
}

.banner-cnt {
    background: rgba(250, 250, 250, 0.45);
    padding: 20px;
    border-radius: 25px;
}

.hwork-cnt {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    height: 325px;
    background-color: #fff;
}

.hw-order {
    border-radius: 6px;
    height: 310px;
    color: #fff;
    position: relative;
}

.hw-order h5{
    font-size: 1.4rem !important;
}

.line-img,.line-dwn,.line-img-2 {
    position: relative;
}

.line-img::after , .line-dwn::after, .line-img-2::after {
    content: '';
    position: absolute;
    background-image: url(./assets/images/ht-line.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: -154px;
    width: 245px;
    top: -9px;
    height: 245px;
    rotate: 20deg;
}

.line-dwn::after {
    top: 3px;
    right: -150px;
    rotate: -9deg;
    background-image: url(./assets/images/ht-down-2.svg);
}

.line-img-2::after {
    top: 12px;
    rotate: 8deg;
}

.hw-order p {
    color: #fff;
}

.day-wise-cnt {
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.product-wrapper{
    display: inline-flex;
    flex-direction: row;
    overflow-x: hidden;
}

.product-wrapper::-webkit-scrollbar {
    display: none;
    height: 0;
  }

.product-container {
    flex-grow: 1;
    width: 230px;
    max-width: 230px;
    min-width: 230px;
    display: inline-flex;
    flex-direction: column;
    border-radius: 8px;
    border: solid 1px #e5e7eb;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.product-container:not(:last-child) {
    margin-right: 15px;
}

.mob-bottom-wrap {
    display: none;
}
.show-cart-items {
    display: block;
}

.product-buttons {
    min-width: 165px;
    max-width: 165px;
    height: 100%;
    position: unset;
    padding: 0 0 0 20px;
    display: inline-flex;
    flex-wrap: wrap;
    align-self: center;
}

.product-buttons button , .product-buttons button span, .button-size {
    width: 152px;
    min-width: 152px;
}

.product-buttons button:not(:last-child), .count-cnt, .button-size {
    margin-bottom: 8px;
}

.justify-content {
    justify-content: flex-end;
}

.secondaryButton {
    border-radius: 25px; 
    padding: 5px 10px; 
    font-size: 0.7rem;
    // box-shadow: 1px 3px 4px #e2e2e2;
    border: 1px solid #e6e6e6;
}

.font-14-btn {
    font-size: 14px;
}

.order-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
    margin-top: 25px;
}

.step-box {
    position: relative;
    // background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    min-width: 154px;
    text-align: center;
    border: 1px solid #2b6e36;
}

.bnr-text {
    font-size: 1.2rem;
    text-align: center;
    color: #000;
}

.hm-text {
    font-size: 1.2rem;
}

.step-box > span {
    font-family: var(--heading-font);
    text-align: center;
    border-radius: 25px;
    background-color: #2b6e36;
    width: 30px;
    color: #fdd835;
    font-size: 1.2rem;
    font-weight: normal;
    display: inline-block;
    position: absolute;
    top: -15px;
    left: 37%;
}

.title-step {
    font-family: var(--heading-font);
    font-size: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
}

.copun-applied {
    color: #3aa138; 
    background-image: url(./assets/images/success.gif); 
    background-repeat: no-repeat; 
    background-position: 95px; 
    background-size: 125px;
}

 @media (max-width: 1399.98px) { 
    .about-theme-bg {
        margin-top: 25px;
    }
    .mob-bottom-wrap {
        display: none;
    }
  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1299.98px) { 
    .about-theme-bg {
        margin-top: 25px;
    }
 }  
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .title-head {
        font-size: 2.5rem;
        margin-bottom: 5px;
    }
    .desp-text {
        font-size: 1rem;
    }
    .hwork-cnt {
        height: 325px;
    }
    .disp-cat-name {
        font-size: 0.7rem !important;
    }

    .line-img::after {
        right: -100px !important;
        width: 193px !important;
        top: 6px !important;
        height: 215px !important;
        rotate: 12deg !important;
    }
    .line-dwn::after {
        top: 27px !important;
        right: -164px !important;
        rotate: 3deg !important;
        height: 215px !important;
    }
    .line-img-2::after {
        top: 26px !important;
        rotate: -3deg !important;
        right: -164px !important;
        height: 215px !important;
    }

 } 
 
 @media (max-width: 768px) { 
    .line-dwn::after {
        display: none;
    }
  }

  .desktop-items {
    display: block;
  }
  .mob-items {
    display: none;
  }
  
 
  @media (min-width: 0px) and (max-width: 576px){
    .page-headings {
        font-size: 2rem;
    }
    .hw-order {
        height: auto !important;
    }
    .banner-wrap {
        width: 95%;
    }
    .justify-content {
        justify-content: flex-start;
        flex-direction: row-reverse;
        gap: 1rem !important;
    }
    .logo-container {
        width: 69px !important;
        // left: calc(50% - 55px) !important;
    }
    .mid-button, .button-size {
        min-width: 120px !important;
        max-width: 100px !important;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .product-buttons {
        padding-left: 0 !important;
    }
    // .padding-mob {
    //     padding: 0 !important;
    // }

    .mob-font {
        font-size: 0.75rem;
        line-height: 0.7rem;
    }
    .pd-sm {
        padding: 0 10px !important;
    }
    .link-primary {
        font-size: 14px !important;
    }
    .copun-applied {
        background-position: 15px; 
        background-size: 95px;
    }
    .pd-page-banner {
        padding: 5px 0 !important;
    }
    h2.page-titles-font {
        font-size: 1.1rem;
    }
    .hwork-cnt {
        height: auto !important;
    }
    .desktop-items {
        display: none;
    }
    .mob-items {
        display: block;
    }
    .font-mob {
        font-size: 0.72rem !important;
    }
    .zero-pad-mob {
        padding: 0 !important;
    }
  }

  @keyframes marquee {
    0% {
      margin-left: 100%;
      transform: translateX(100%);
    }
    100% {
      margin-left: 0;
      transform: translateX(-100%);
    }
  }

  @media (min-width: 0px) and (max-width: 767.98px) {
    .line-dwn::after {
        display: none;
    }
    .line-img:after {
        display: none;
    }
    .line-img-2:after {
        display: none;
    }
    .title-head {
        line-height: 2.9rem;
    }
    .gap-sm-3 {
        gap: 1rem;
    }
    .mob-scroll-btns {
        display: inline-flex !important;
    }
    .bg-notification {
        font-size: 0.75rem;
    }
    .mt-100 {
        margin-top: 92px !important;
    }
    .menu-sticky {
        top: 125px !important;
    }
    .margin-mob-btn {
        margin: 15px;
    }
    .announce-mob {
        font-size: 12px !important;
    }
    .pad-mob-nt {
        padding: 5px !important;
    }
    .about-msg {
        margin-left: 0 !important;
    }
    .marquee {
        animation: marquee 15s linear infinite;
        display: inline-block;
        white-space: nowrap;
        transition: display 1s ease-in-out;
        &:hover {
          animation-play-state: paused;
        }
      }
  }

  @media (min-width: 0px) and (max-width: 912px) {
    // #navbar ul{
    //     display: none;
    // }
    .product-buttons {
        font-size: 0.75rem !important;
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    .mob-font {
        font-size: 0.7rem;
        line-height: 0.9rem;
    }
    .mob-bottom-wrap {
        display: block;
    }
    .show-cart-items {
        display: none;
    }
    .pd-mobile:last-child {
        padding-bottom: 15px;
    }

    .bg-notification {
        overflow: hidden;
    }
    .pb-mob {
        padding-bottom: 60px;
    }

   } 

  @media (min-width: 768px) and (max-width: 992px){
    .line-dwn::after {
        display: none;
    }
    .mob-font {
        font-size: 0.85rem;
        line-height: 0.9rem;

    }
    .pd-mobile:last-child {
        padding-bottom: 120px;
    }
  }
  @media (min-width: 560px) and (max-width: 992px){
    .justify-content {
        justify-content: flex-start;
        flex-direction: row-reverse;
        gap: 1rem !important;
    }
    .mob-bottom-wrap {
        display: block;
    }

    .show-cart-items {
        display: none;
    }
    // .logo-container {
    //     left: calc(50% - 108px) !important;
    // }
  }
@media (min-width: 768px) and (max-width: 1199px) {
    .title-head {
        line-height: 3.2rem !important;
    }
    .container-bg {
        min-height: calc(75vh - 96px) !important;
    }
    .hw-order-hd {
        font-size: 3.8rem !important;
    }
    .hwork-cnt {
        height: 320px !important;
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
  }
  @media (min-width: 1025px) {
    .product-wrapper.shadowRight::after {
        right: 150px;
    }
    .product-wrapper {
        max-width: calc(100% - 165px);
    }
  }
  @media (max-width: 940px) { 
    .product-buttons {
        flex-wrap: nowrap !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .product-buttons button, .button-size {
        min-width: none !important;
        min-height: 35px;
        max-height: 35px;
    }
    .gap-sm {
        gap: 3px;
    }
    .min-btn-size {
        min-width: 50px !important;
        max-width: 50px !important;
    }
  }

 @media (min-width: 1024px) and (max-width: 1494px) {
    .product-wrapper.shadowRight::after {
        position: absolute;
        z-index: 1;
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        width: 20px;
        background: linear-gradient(271deg, #ffffff 15%, rgba(255, 255, 255, 0) 99.06%);
    }
 }

 @media (min-width: 320px) and (max-width: 419.99px){ 
    .banner-cnt {
        margin: 30px 0 !important;
        padding: 10px !important;
    }
    .title-head {
        font-size: 2.2rem !important;
        line-height: 2.6rem !important;
    }
    .bnr-text {
        font-size: 1rem !important;
    }
    .order-steps {
        flex-wrap: nowrap !important;
        gap: 8px;
    }
    .step-box {
        padding: 10px 6px;
        // width: 80px;
        min-width: 102px;
    }
    .step-box > span {
        width: 26px;
        // left: 30px;
        font-size: 1.1rem;
    }
    .title-step {
        font-size: 0.9rem;
        line-height: 1rem;
    }
 }
 @media (min-width: 0px) and (max-width: 319.99px){ 
    .banner-cnt {
        margin: 30px 0 !important;
    }
    .title-head {
        font-size: 2.2rem !important;
        line-height: 2.6rem !important;
    }
    .bnr-text {
        font-size: 1rem !important;
    }
    .step-box {
        padding: 10px 6px;
        // width: 80px;
        min-width: 80px;
    }
    .step-box > span {
        width: 26px;
        // left: 30px;
        font-size: 1.1rem;
    }
    .title-step {
        font-size: 0.9rem;
        line-height: 1rem;
    }
    .set-label-size {
        font-size: 1.24rem;
    }
 }

 @media (min-width: 0px) and (max-width: 356.99px){ 
    .item-sel-text {
        font-size: 10px !important;
    }
    .faq-list {
        font-size: 0.78rem !important;
    }
    .item-cnt-mob {
        width: 95% !important;
        left: 2% !important;
    }
 }

 .modal-header {
    position: sticky !important;
    top: 0;
    background-color: inherit;
    z-index: 1055;
}

.btn-width {
    width: 225px;
}

.icon-spice {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 10px;
    background-image:  url(./assets/images/icon-chilli.png);
    background-repeat: no-repeat;
    background-size: cover;
    backface-visibility: 0.5;
}

.bg-contact-us,.bg-about-us {
    position: relative;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.25);
}

.bg-about-us {
    background-color: #fff;
}

.bg-about-us p {
    color: #000 !important;
}

.bg-contact-us::before,.bg-about-us::before {
    content: "";
    background-image: url(./assets/images/banner/MakeMyDabba-bg-about.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.4;
}

.bg-about-us::before {
    background-image: url(./assets/images/banner/MakeMyDabba-bg-about.jpg);
}

.polocy-cnt p{
    font-size: 0.8rem;
}
.btn-theme-yellow:disabled {
    background-color: #ffecc4;
    border: 1px solid var(--primary-color);
    color: #b8a781;
}
.pd-page-banner {
    padding: 12px 0;
}

.alert-danger {
    background-color: #d81a1a !important;
    border-color: #EF5350 !important;
}
.alert-danger span, .alert-success span {
    color: #fff !important;
}
.alert-success {
    background-color: #359800 !important;
    border-color: #4CAF50 !important;
}
.item-sel-text{
    display: inherit; font-size: 12px; text-align: center; padding: 4px 8px;
}

.form-check-input[type=checkbox] {
    border-color: #757575;
}

.faq-font-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 15px;
}
.accordion-button {
    font-size: 0.85rem !important;
    padding: 10px;
}
.faq-list {
    font-size: 0.85rem;
}
.accordion-body {
    padding: 10px !important;
}
.faq-list li:not(:last-child) {
    margin-bottom: 6px;
}
.accordion-button:focus {
    box-shadow: none !important;
    border-color: transparent !important;
}

.color-green-text {
    color: #078f68;
}
